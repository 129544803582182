var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.active
      ? _c("div", [
          _c("label", { attrs: { for: "label" } }, [_vm._v(" Label Name:  ")]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.labelText,
                expression: "labelText"
              }
            ],
            attrs: { name: "label" },
            domProps: { value: _vm.labelText },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.labelText = $event.target.value
                },
                _vm.update
              ]
            }
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("label", { attrs: { for: "html" } }, [_vm._v(" Html popup:  ")]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.popupHtml,
                expression: "popupHtml"
              }
            ],
            attrs: { name: "html" },
            domProps: { value: _vm.popupHtml },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.popupHtml = $event.target.value
                },
                _vm.update
              ]
            }
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("label", { attrs: { for: "type" } }, [_vm._v("  Type:  ")]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.featureType,
                expression: "featureType"
              }
            ],
            attrs: { name: "type" },
            domProps: { value: _vm.featureType },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.featureType = $event.target.value
                },
                _vm.update
              ]
            }
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v("\n  \n  " + _vm._s(_vm.labelText) + " "),
          _c("br"),
          _vm._v("\n  " + _vm._s(_vm.popupHtml) + " "),
          _c("br"),
          _vm._v("\n  " + _vm._s(_vm.featureType) + " "),
          _c("br")
        ])
      : _c("div", [_vm._v("\n  None\n")]),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "m-map__export",
        attrs: { href: "#" },
        on: { click: _vm.save }
      },
      [_vm._v("Save layer")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }