<template>
  <div>
<div v-if="active"> 
  <label for="label"> Label Name:  </label>  <br/>
  <input name="label" v-model="labelText" v-on:input="update"/>  <br/>
  
  <label for="html"> Html popup:  </label>  <br/>
  <textarea name="html" v-model="popupHtml" v-on:input="update"> </textarea>  <br/>
  
  <label for="type">  Type:  </label>  <br/>
  <input name="type" v-model="featureType" v-on:input="update"/>  <br/>
  
  {{ labelText }} <br/>
  {{ popupHtml}} <br/>
  {{ featureType}} <br/>
</div>
<div v-else>
  None
</div>
<a href="#" class="m-map__export" v-on:click="save">Save layer</a>
</div>

</template>

<script>

export default {
    data() {
	return {
	    active: false,
	    labelText: null,
	    popupHtml: null,
	    featureType: null
	}
    },
    methods: {
	update: function() {
	    this.updateCallback(this.labelText, this.popupHtml, this.featureType);
	},
	switch: function(opts) { 
	    this.active = true;
	    this.updateCallback = opts.callback ;
	    this.labelText = opts.labelText;
	    this.popupHtml = opts.popupHtml;
	    this.featureType = opts.featureType;
	},
	updateCallback: function(labelName, html, type) {
	    return null;
	},
	save: function() {
	    var layerName, json;
	    ({layerName, json} = this.saveCallback());
	    fetch("/api/layer/save/" + layerName, {
		method: 'post',
		body: json
	    });
	}
    }
}
  
</script>
