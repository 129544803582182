
<template>
<select class="m-map__select" v-model="selected">
  <option v-for="layer in layers" v-bind:value="layer">
    {{ layer }}
  </option>
</select>

</template>

<script>
import {LayerSwitcher} from './LayerSwitcher.js';
export default {
    props: ['layers'],
    data() {return {
	selected: null
    }},
    methods: {},
    mounted() {
	if (window.location.hash.slice(0, 7) == '#layer_') {
	    this.selected = decodeURI(window.location.hash.slice(7));
	} else {
	    this.selected = this.layers[0];
	}

	window.layerSwitcher = new LayerSwitcher(this.selected, map);
    },
    watch: {
	selected(newVal, oldVal) {
	    if (oldVal != null) 
		window.layerSwitcher.switchLayer(this.selected);
	}
    }
}
  
</script>
