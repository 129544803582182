import Vue from 'vue';
import MapEdit from './MapEdit.vue';
import Select from './Select.vue';
import {MapFactory} from './MapFactory.js';
import {RasterCoordsFactory} from './RasterCoordsFactory.js';
import {LayerSwitcher} from './LayerSwitcher.js';
import TurbolinksAdapter from '../vue-turbolinks.js';
Vue.use(TurbolinksAdapter)

window.mapInit = function(layers) {
    
    
    var imageDimensions = [
	17759,  // original width of image
	15449   // original height of image
    ];

    window.map = MapFactory(imageDimensions, 'm-map__map');
    var rasterCoords = RasterCoordsFactory(map, imageDimensions);

    new Vue({
	el: '#m-map__select',
	render: h => h(Select, {ref: 'select', props: {layers: layers}})
    });

    // Edit box
    if (window.loggedin) {
	window.app = new Vue({
	    el: '#m-map__map-edit',
	    render: h => h(MapEdit, {ref: 'edit'})
	});
    }
}
