require('./leaflet.draw.js');

var LayerSwitcher = function (layerName, map) {
    this.currentLayerName = layerName;
    this.currentLayer = null;
    this.drawControl = null;
    this.map = map;

    this.getMap(layerName);
};

LayerSwitcher.prototype.switchLayer = function (layerName) {
    this.currentLayerName = layerName;

	this.map.removeLayer(this.currentLayer);


    if (window.loggedin && this.drawControl) {
	this.map.removeControl(this.drawControl);
    }
    this.getMap(layerName);
};

LayerSwitcher.prototype.getMap = function (newLayerName) {
    var self = this;
    fetch( "/api/layer/get/" + newLayerName).then((response) => {
	response.json().then(function(data) {
	    self._switch(JSON.parse(data[0].json));
	    if (newLayerName == 'Heritage') {
		var imageUrl = '/content/themes/kings_hill/static/images/map-runway.png',
		    imageBounds = [L.latLng(85.0511287798066, -180),L.latLng(10.217625353199578,15.106201171875002)]
		L.imageOverlay(imageUrl, imageBounds).addTo(self.currentLayer);	
	    }
	    else if (newLayerName == 'Bus routes') {
		var imageUrl = '/content/themes/kings_hill/static/images/map-bus-routes.png';
		imageBounds = [L.latLng(85.0511287798066, -180),L.latLng(10.217625353199578,15.106201171875002)]
		L.imageOverlay(imageUrl, imageBounds).addTo(self.currentLayer);	
	    }


	});
    });

}

LayerSwitcher.prototype.editBoxBind = function(layer) {
    return function(e) {
	var feature = layer.feature = layer.feature || {};
	layer.feature.properties = layer.feature.properties || {};

	window.app.$refs.edit.switch({
	    labelText: feature.properties.labelText == undefined ? '' : feature.properties.labelText,
	    popupHtml: feature.properties.popupHtml == undefined ? '' : feature.properties.popupHtml,
	    featureType: feature.properties.featureType == undefined ? '' : e.target.feature.properties.featureType,
	    
	    callback: function(labelText,popupHtml,featureType) {
		feature.properties.labelText = labelText;
		layer.unbindTooltip();
		layer.bindTooltip(feature.properties.labelText, { permanent: true });

		//
		feature.properties.popupHtml = popupHtml;
		layer.bindPopup(popupHtml,{maxWidth: 350});
		layer._map.closePopup();
		layer.openPopup();
	    }});
    }
};


LayerSwitcher.prototype._switch = function (json) {
    var self = this;
    // json -> geojson -> layer
    this.currentLayer = L.geoJson(json, {
	onEachFeature: function (feature, layer) {	    
	    if ('setStyle' in layer) {
		layer.setStyle({
	    	    'opacity': 0,
	    	    'fillOpacity': 0.2,
	    	    'color': "#ee5357"
		});

		
		layer.on('mouseover', function () {
	    	    this.setStyle({
	    		'opacity': 0.0,
	    		'fillOpacity': 0.6
	    	    });
		});
		
		layer.on('mouseout', function () {
	    	    this.setStyle({
	    		'opacity': 0,
	    		'fillOpacity': 0.2
	    	    });
		});
	    }
	    
	    if (feature.properties) {
		if (feature.properties.popupHtml) {
		    layer.bindPopup(feature.properties.popupHtml,{maxWidth: 350});
		}
		if (feature.properties.labelText) {
		    layer.bindTooltip(feature.properties.labelText);
		}
	    }

	    if (window.loggedin) {
		layer.on('click', self.editBoxBind(layer));
	    }
	},
	pointToLayer: function (feature, latlng) {

	    let opts = Object.assign({}, L.Icon.Default.prototype.options);
	    opts.iconRetinaUrl = '/content/themes/kings_hill/static/images/map-marker.png';
	    opts.iconUrl = '/content/themes/kings_hill/static/images/map-marker.png';
	    opts.shadowUrl = null;
	    opts.shadowRetinaUrl = null;
	    opts.iconSize = [40, 40];
	    opts.iconAnchor = [20, 40];
	    
	    let myIcon = L.icon(opts);
	    let marker = L.marker(latlng, {icon: myIcon});

	    
	    
	    return marker;
	},
    });

    // Add Layer
    this.map.addLayer(this.currentLayer);

   
    if (window.loggedin) {
	// Bind Save
	window.app.$refs.edit.saveCallback = function() {
	    return {layerName: self.currentLayerName,
		    json: JSON.stringify(self.currentLayer.toGeoJSON().features)};
	};

	// Add control
	this.drawControl = new L.Control.Draw({
	    edit: {
		featureGroup: this.currentLayer
	    }
	});
	this.map.addControl(this.drawControl);

	this.map.on('draw:created', function(e) {
	    self.currentLayer.addData(e.layer.toGeoJSON());
	});
    }
}

LayerSwitcher.getLayerNames = function () {
    return { then: function(callback) {
	fetch('/api/layer/getNames/')
	    .then((response) => {
		response.json().then(function(data) {
		    callback(data);
		});
	    });
    }};
};


export {LayerSwitcher};
