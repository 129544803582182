require('./leaflet.js');
require('./leaflet.draw.js');
require('./Leaflet.fullscreen.js');

function MapFactory(img, id) {
    var minZoom = 1,
	maxZoom = 8;

    // create the map
    var map = L.map(id,{
	minZoom: minZoom,
	maxZoom: maxZoom,
	preferCanvas: true,
	zoomDelta: 2,
	drawControl: false,
	fullscreenControl: {
            pseudoFullscreen: false // if true, fullscreen to page width and height
	}
    });

    document.addEventListener('turbolinks:visit', function teardown() {
	map.remove();
	document.removeEventListener('turbolinks:visit', teardown);
    });

    L.tileLayer('/uploads/tiles/{z}/{x}/{y}.png', {
	noWrap: true,
	updateWhenZooming: false,
    }).addTo(map);

    var lastZoom = map.getZoom();
    map.on('zoomend', function() {
	var zoom = map.getZoom();
	var tooltipThreshold = 2;
	if (zoom < tooltipThreshold && (!lastZoom || lastZoom >= tooltipThreshold)) {
	    map.eachLayer(function(l) {
		if (l.getTooltip()) {
		    var tooltip = l.getTooltip();
		    l.unbindTooltip().bindTooltip(tooltip, {
			permanent: false
		    })
		}
	    })
	} else if (zoom >= tooltipThreshold && (!lastZoom || lastZoom < tooltipThreshold)) {
	    map.eachLayer(function(l) {
		if (l.getTooltip()) {
		    var tooltip = l.getTooltip();
		    l.unbindTooltip().bindTooltip(tooltip, {
			permanent: true
		    })
		}
	    });
	}
	lastZoom = zoom;
    });

    /* 
     * Workaround for 1px lines appearing in some browsers due to fractional transforms
     * and resulting anti-aliasing.
     * https://github.com/Leaflet/Leaflet/issues/3575
     */
    (function(){
	var originalInitTile = L.GridLayer.prototype._initTile
	L.GridLayer.include({
            _initTile: function (tile) {
		originalInitTile.call(this, tile);

		var tileSize = this.getTileSize();

		tile.style.width = tileSize.x + 1 + 'px';
		tile.style.height = tileSize.y + 1 + 'px';
            }
	});
    })()

    return map;
}

export { MapFactory };
